h5 {
    font-family: "Gmarket-sans-medium";
}


.summary_title {
    font-family: "Gmarket-sans-bold";
}



@font-face {
    font-family: "Dongle";
    src: url('../fonts/Dongle-Bold.ttf');
}

@font-face {
    font-family: "Jua";
    src: url('../fonts/Jua-Regular.ttf');
}

@font-face {
    font-family: "Nanum-ExtraBold";
    src: url('../fonts/NanumGothic-ExtraBold.ttf')
}

@font-face {
    font-family: "Nanum-bold";
    src: url('../fonts/NanumGothic-Bold.ttf')
}

@font-face {
    font-family: "Nanum-regular";
    src: url('../fonts/NanumGothic-Regular.ttf')
}

@font-face {
    font-family: "Gmarket-sans-bold";
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff');
}

@font-face {
    font-family: "Gmarket-sans-medium";
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff');
}

@font-face {
    font-family: "Gmarket-sans-light";
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansLight.woff');
}

@font-face {
    font-family: "SeoulNamsanB";
    src: url('../fonts/SeoulNamsanB.ttf');
}
@font-face {
    font-family: "SeoulNamsanM";
    src: url('../fonts/SeoulNamsanM.ttf');
}
@font-face {
    font-family: "RammettoOneRegular";
    src: url('../fonts/RammettoOne-Regular.ttf');
}
@font-face {
    font-family: 'Noto Sans';
    src: url('../fonts/NotoSansKR-VariableFont_wght.ttf');
}
