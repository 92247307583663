/* 
body > #root > div {
  height: 100vh;
} */

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

#newroot {
	height: 100vh;
	overflow-y: scroll;
}

.fullHeight {
	height: 100vh;
	overflow-y: scroll;
}
input {
	outline: none;
}
